import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'react-phone-number-input';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import CountryCodeSelect from '../../../shared/CountryCodeSelect';
import ActionEditorBase from '../ActionEditorBase';
import ShowInResourceView from '../ShowInResourceView';

const PhoneNumberResourceEditor: FC<EditorProps> = (props) => {
  const { action, patchData } = props;
  const { countryCode } = action?.data || {};
  const { t } = useTranslation('form-builder');

  const onCountryCodeCange = (value: Country) => {
    patchData({ countryCode: value });
  };
  return (
    <div data-cy="phone-number-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-dpm-12">{t('action-properties.common.internation-code')}</div>
        <div>
          <CountryCodeSelect onChange={onCountryCodeCange} value={countryCode} />
        </div>
      </div>

      <ShowInResourceView {...props} />
    </div>
  );
};

export default PhoneNumberResourceEditor;
